import React from 'react'

const questionBanner = (props) => (
    <section id="banner" className="style2" style={{height:'600px'}}>
        <div className="inner">
            <header className="major">
                <h1>常見問題</h1>
            </header>
            <div className="content">
                <p>你想瞭解三健客3musclers台北健身房是怎麼收費的嗎？又要如何加入會員？針對台北的健身課程、訓練計劃、收費、諮詢、設備等，三健客皆能貼心為您解答，若想向內湖、板橋、永和各健身房分館諮詢，也歡迎直接來電洽詢喔！
                </p>
            </div>
        </div>
    </section>
)

export default questionBanner
