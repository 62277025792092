import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import QuestionBanner from '../components/questionBanner'
import question from '../assets/images/question.svg'
import plus from '../assets/images/more.svg'
import minus from '../assets/images/minus.svg'

const Question = props => {
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open8, setOpen8] = useState(false)
  const [open9, setOpen9] = useState(false)
  const [open10, setOpen10] = useState(false)
  const [open11, setOpen11] = useState(false)
  const [open12, setOpen12] = useState(false)
  const [open13, setOpen13] = useState(false)
  const [open14, setOpen14] = useState(false)
  const [open15, setOpen15] = useState(false)
  const [open16, setOpen16] = useState(false)
  const [open17, setOpen17] = useState(false)
  const [open18, setOpen18] = useState(false)
  const [open19, setOpen19] = useState(false)
  const [open20, setOpen20] = useState(false)
  const [open21, setOpen21] = useState(false)
  const [open22, setOpen22] = useState(false)
  const [open23, setOpen23] = useState(false)
  return (
    <Layout>
      <Helmet>
        <title>常見健身QA｜三健客3musclers健身俱樂部</title>
        <meta name="description" content="你想瞭解三健客3musclers台北健身房是怎麼收費的嗎？有年費嗎？又要如何加入會員？針對台北的健身課程、訓練計劃、收費、諮詢、設備等，三健客皆能貼心為您解答，若想向內湖、板橋、永和各健身房分館諮詢，也歡迎直接來電洽詢喔！" />
        <meta name="keywords" content="三健客,健身房,內湖健身房,板橋健身房,永和健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程" />
      </Helmet>
		<QuestionBanner/>
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <a href="#price" className="button" style={{ margin: '5px' }}>
              費用問題
            </a>
            <a href="#describe" className="button" style={{ margin: '5px' }}>
              訂閱問題
            </a>
            <a href="#member" className="button" style={{ margin: '5px' }}>
              會籍問題
            </a>
            <a href="#place" className="button" style={{ margin: '5px' }}>
              場館問題
            </a>
            <a href="#lesson" className="button" style={{ margin: '5px' }}>
              課程問題
            </a>
            <h5 style={{ marginTop: '20px' }}>
              如果常見問答找不到你要的解答的話，歡迎直接來電詢問
            </h5>
            <h5>內湖店：02-8751-1915</h5>
            <h5>板橋店：02-8258-8025</h5>
            <h5>永和店：02-8942-2056</h5>
            <header className="major" id="price" style={{ marginTop: '20px' }}>
              <h3>費用問題</h3>
            </header>

            <div
              className={open1 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen1(!open1)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>你們是怎麼收費的？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open1 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open1 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open1 ? 'answer' : 'display-none'}>
                <p>
						內湖店為工作室沒有開放會員訓練
						<br/>
                  板橋與永和店是訂閱制，目前兩間店的會籍是分開的，你可以選擇
                  <br />
                  永和金卡：1088/月
						<br />
						板橋金卡：900/月
                  <br />
						單週不限入場次數：299/週
						<br/>
						單次入場不限時間：150/人
						<br/>
						分鐘計費：1.2/分
						<br/>
                  金卡無使用時段限制
                </p>
              </div>
            </div>
            <div
              className={open2 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen2(!open2)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>你們有年費嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open2 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open2 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open2 ? 'answer' : 'display-none'}>
                <p>
                  沒有喔，我們是訂閱制，按月從 App 扣款</p>
						<p>
						內湖店為工作室沒有開放會員訓練
						<br/>
                  板橋與永和店是訂閱制，目前兩間店的會籍是分開的，你可以選擇
                  <br />
                  金卡：1088/月
                  <br />
						板橋金卡：900/月
                  <br />
						單週不限入場次數：299/週
						<br/>
						單次入場不限時間：150/人
						<br/>
						分鐘計費：1.2/分
						<br/>
                  金卡無使用時段限制
                </p>
                
              </div>
            </div>
            <div
              className={open3 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen3(!open3)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>那有賣單次入場嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open3 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open3 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open3 ? 'answer' : 'display-none'}>
                <p>單次入場費用為 150 元，板橋永和都一樣。</p>
              </div>
            </div>
            <div
              className={open4 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen4(!open4)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>加入會員需要入會費或是手續費嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open4 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open4 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open4 ? 'answer' : 'display-none'}>
                <p>
                  不需要入會費及手續費！！
                </p>
              </div>
            </div>
            <header
              className="major"
              id="describe"
              style={{ marginTop: '20px' }}
            >
              <h3>訂閱問題</h3>
            </header>
            <div
              className={open5 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen5(!open5)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>我要怎麼訂閱？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open5 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open5 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open5 ? 'answer' : 'display-none'}>
                <p>
                  首先你必須下載我們的APP，接著按照註冊流程就可以完成訂閱囉！
                </p>
              </div>
            </div>
            <div
              className={open6 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen6(!open6)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>取消訂閱會收手續費嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open6 ? 'display-none' : ''}
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open6 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open6 ? 'answer' : 'display-none'}>
                <p>取消後續訂閱的話是不會收任何手續費!</p>
                <p>但如果是退費的話，請來電詢問！</p>
                <p>內湖店：02-8751-1915</p>
                <p>板橋店：02-8258-8025</p>
                <p>永和店：02-8942-2056</p>
              </div>
            </div>
            <div
              className={open7 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen7(!open7)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>如果取消訂閱後，又再重新訂閱一次，會額外收費嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open7 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open7 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open7 ? 'answer' : 'display-none'}>
                <p>不會！</p>
                <p>
                  取消訂閱要在App上顯示的扣款日期前取消，不然就會視為續約下一期！
                </p>
              </div>
            </div>
            <header className="major" id="member" style={{ marginTop: '20px' }}>
              <h3>會籍問題</h3>
            </header>
            <div
              className={open8 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen8(!open8)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>會籍有限制使用時段嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open8 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open8 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open8 ? 'answer' : 'display-none'}>
                <p>
                  金卡：無使用時段限制，
                  <br />
                  金卡無使用時段限制
                </p>
              </div>
            </div>
            <div
              className={open23 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen23(!open23)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>請問板橋店跟永和店可以通館嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open23 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open23 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open23 ? 'answer' : 'display-none'}>
                <p>
                  為了回饋三健客老會員，凡是在2020年5/15號之前購買金卡、銀卡都享有板橋跟永和通館的資格（必須是在籍會員）
						<br/>
						必須特別注意之後如果取消訂閱之後就不在享有此資格！
                </p>
              </div>
            </div>
            <div
              className={open9 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen9(!open9)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>會籍有限制使用次數嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open9 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open9 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open9 ? 'answer' : 'display-none'}>
                <p>沒有，但有限制使用時段！</p>
                <p>
                  金卡：無使用時段限制
                </p>
              </div>
            </div>
            <div
              className={open10 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen10(!open10)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>
                  如果我突然受傷、出差、出國等等，導致我有一段時間不能來使用，我可以暫停或退掉會籍嗎？
                </p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open10 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open10 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open10 ? 'answer' : 'display-none'}>
                <p>可以暫停會籍，請依規定提前來電由專人協助你辦理暫停。</p>
                <p>內湖店：02-8751-1915</p>
                <p>板橋店：02-8258-8025</p>
                <p>永和店：02-8942-2056</p>
              </div>
            </div>
            <header className="major" id="place" style={{ marginTop: '20px' }}>
              <h3>場館問題</h3>
            </header>
            <div
              className={open11 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen11(!open11)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>營業時間是幾點到幾點？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open11 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open11 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open11 ? 'answer' : 'display-none'}>
                <p>
                  內湖店：週一到週日 09:00~22:00
                  <br />
                  板橋店：週一到週日 07:00~23:00
                  <br />
                  永和店：週一到週日 07:00~24:00
                  <br />
                  颱風天、每月場館清潔、過年期間等營業時間請密切留意粉絲專頁資訊。
                </p>
              </div>
            </div>
            <div
              className={open12 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen12(!open12)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
                />
                <p>該怎麼聯絡你們？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open12 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open12 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open12 ? 'answer' : 'display-none'}>
                <p>
                  內湖店：02-8751-1915
                  <a
                    href="https://www.facebook.com/3musclers/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  ></a>
                </p>
                <p style={{ marginTop: '10px' }}>
                  板橋店：02-8258-8025
                  <a
                    href="https://www.facebook.com/%E4%B8%89%E5%81%A5%E5%AE%A2-3musclers-%E6%9D%BF%E6%A9%8B%E5%BA%97-125554492170293/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  >
                    <span className="label">Facebook</span>
                  </a>
                </p>
                <p style={{ marginTop: '10px' }}>
                  永和店：02-8942-2056
                  <a
                    href="https://www.facebook.com/3muscler/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  >
                    <span className="label">Facebook</span>
                  </a>
                </p>
              </div>
            </div>
            <div
              className={open13 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen13(!open13)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>可以直接去參觀你們場館嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open13 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open13 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open13 ? 'answer' : 'display-none'}>
                <p>
                  當然可以，但還是希望你來之前先跟我們電話告知或是私訊粉專，以避免當下因現場人員都在忙碌中，而無法及時為你服務。
                </p>
                <p>
                  內湖店：02-8751-1915
                  <a
                    href="https://www.facebook.com/3musclers/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  ></a>
                </p>
                <p style={{ marginTop: '10px' }}>
                  板橋店：02-8258-8025
                  <a
                    href="https://www.facebook.com/%E4%B8%89%E5%81%A5%E5%AE%A2-3musclers-%E6%9D%BF%E6%A9%8B%E5%BA%97-125554492170293/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  >
                    <span className="label">Facebook</span>
                  </a>
                </p>
					 <p style={{ marginTop: '10px' }}>
                  永和店： 02-8942-2056
                  <a
                    href="https://www.facebook.com/3muscler/"
                    className="icon alt fa-facebook"
                    style={{ marginLeft: '10px' }}
                  >
                    <span className="label">Facebook</span>
                  </a>
                </p>
              </div>
            </div>
            <div
              className={open14 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen14(!open14)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>你們場館可以吃東西嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open14 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open14 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open14 ? 'answer' : 'display-none'}>
                <p>可以，但要在飲食區，運動區域是禁止飲食的（高蛋白除外）。</p>
              </div>
            </div>
            <div
              className={open15 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen15(!open15)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有飲水機嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open15 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open15 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open15 ? 'answer' : 'display-none'}>
                <p>有，三個館皆有配置飲水機。</p>
              </div>
            </div>
            <div
              className={open16 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen16(!open16)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有淋浴間可以盥洗嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open16 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open16 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open16 ? 'answer' : 'display-none'}>
                <p>
                  有，內湖店有一間，板橋店男女各一間，永和店女兩間、男三間。
                </p>
              </div>
            </div>
            <div
              className={open17 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen17(!open17)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有提供沐浴乳、洗髮精嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open17 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open17 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open17 ? 'answer' : 'display-none'}>
                <p>沒有，請會員自行攜帶。</p>
              </div>
            </div>
            <div
              className={open18 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen18(!open18)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有提供毛巾嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open18 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open18 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open18 ? 'answer' : 'display-none'}>
                <p>沒有，請會員自行攜帶毛巾。</p>
              </div>
            </div>
            <div
              className={open19 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen19(!open19)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有規定一定要穿什麼服裝才能進場運動嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open19 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open19 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open19 ? 'answer' : 'display-none'}>
                <p>
                  為了你運動時的安全，在我們場館運動的時候，請一律穿著「運動服裝」。
                </p>
              </div>
            </div>
            {/* <div
              className={open20 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen20(!open20)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>可以脫鞋子訓練嗎？因為我很常看到有人脫鞋子訓練</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open20 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open20 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open20 ? 'answer' : 'display-none'}>
                <p>可以，但不要赤腳在場館內移動！</p>
                <p>
                  另外也不用擔心在我們場館赤腳訓練會很髒或不衛生，我們每天都會清潔，以維持場館的整潔。
                </p>
              </div>
            </div> */}
            <header className="major" id="lesson" style={{ marginTop: '20px' }}>
              <h3>課程問題</h3>
            </header>
            <div
              className={open21 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen21(!open21)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>一對一教練課的費用是多少？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open21 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open21 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open21 ? 'answer' : 'display-none'}>
                <p>
                  關於課程價錢問題，請直接
                  <Link
                    to='/coach'
                    className="button"
                    style={{ margin: '10px' }}
                  >
                    點此連結
                  </Link>
                </p>
              </div>
            </div>
            <div
              className={open22 ? 'question height100' : 'question '}
              onClick={() => {
                setOpen22(!open22)
              }}
            >
              <div
                className="questionBox"
                style={{ position: 'relative', padding: '10px' }}
              >
                <img
                  src={question}
                  style={{ width: '24px', height: '24px', marginLeft: '5px' }}
						alt=''
                />
                <p>有團體課嗎？</p>
                <img
                  src={plus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open22 ? 'display-none' : ''}
						alt=''
                />
                <img
                  src={minus}
                  style={{
                    width: '16px',
                    position: 'absolute',
                    right: '15px',
                    top: '15px',
                  }}
                  className={open22 ? '' : 'display-none'}
						alt=''
                />
              </div>
              <div className={open22 ? 'answer' : 'display-none'}>
                <p>
					 	有，詳情請
                  <Link
                    to='/groupLesson'
                    className="button"
                    style={{ margin: '10px' }}
                  >
                    點此連結
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Question
